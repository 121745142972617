<template>
  <BaseModal
    :config="config"
    @close="onHideModal"
  >
    <template #content>
      <FormResetPassword />
    </template>
  </BaseModal>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import BaseModal from '~/components/common/base-modal.vue'
import FormResetPassword from '~/components/common/form/reset-password'
import useModal from '~/composables/useModal'
import { useAppStore } from '~/store/app'
import { MODAL_TYPES } from '~/config/constant'
import { ModalConfig } from '~/types/modal.type'
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const route = useRoute()
const { openModal } = useModal()
const config: ModalConfig = {
  id: MODAL_TYPES.RESET_PASSWORD,
  centered: true,
  hideClose: false,
  fullscreen: false,
  iconClose: 'icon-close-circle-fill',
  textBack: 'Back',
  noCloseOnBackdrop: true
}

const onHideModal = () => {
  navigateTo({
    path: location.pathname,
    query: { ...route.query, popup: undefined, token: undefined }
  })
}
</script>
<style lang="scss" scoped src="assets/scss/components/common/form/reset-password.scss"></style>
