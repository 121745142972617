<template>
  <div class="forgot-password">
    <form class="form-content">
      <BaseInput
        ref="emailRef"
        v-model="email"
        :label="FORM_AUTH.LABEL_FORGOT_PASSWORD"
        type="email"
        placeholder=" "
        :max-length="$config.MAX_LENGTH_EMAIL"
        :min-length="$config.MIN_LENGTH_EMAIL"
        :limit-input="$config.MAX_LENGTH_EMAIL"
        :close="false"
        required
        @keyup.enter.prevent="submit"
      />
      <div class="redirect">
        {{ FORM_AUTH.DESC_FORGOT_PASSWORD }}
      </div>
      <BaseButton
        :is-loading="isLoadingSubmit"
        :disabled="!email"
        class="base-button--btn-primary"
        type="submit"
        @click="submit"
      >
        Xác nhận
      </BaseButton>
    </form>
  </div>
</template>
<script setup lang="ts">
import BaseInput from '~/components/common/base-input.vue'
import BaseButton from '~/components/common/base-button.vue'
import useModal from '~/composables/useModal'
import { useUserApi } from '~/api/user'
import { FORM_AUTH } from '~/constants/form/auth'
import { FORGOT_PASSWORD_TOAST } from '~/constants/toast'
const { FORGOT_PASSWORD, VERIFY_EMAIL } = useUserApi()
const { $axios, $alertMsg, $device, $config, $openLiveChat } = useNuxtApp()
const { closeAllModal } = useModal()
const router = useRouter()
const route = useRoute()
const email = ref<string>('')
const emailRef = ref<HTMLElement | any>(null)
const isLoadingSubmit = ref<boolean>(false)

const submit = async (): Promise<void> => {
  if (isLoadingSubmit.value) {
    return
  }
  try {
    const isValidate = await emailRef.value?.validate()
    if (isValidate) {
      isLoadingSubmit.value = true
      const resCheckEmail = await $axios.$post<{ data: { exist: boolean } }>(
        VERIFY_EMAIL,
        { email: email.value },
        { withCredentials: true }
      )
      if (resCheckEmail && resCheckEmail?.exist) {
        const resForgot = await $axios.$post<{ status: string; message?: string }>(
          FORGOT_PASSWORD,
          { email: email.value },
          { withCredentials: true }
        )
        if (resForgot && resForgot.status === 'OK') {
          $alertMsg('success', resForgot.message || FORGOT_PASSWORD_TOAST.SUCCESS)
          if ($device.isMobileOrTablet) {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          }
          hideModal()
        } else {
          $alertMsg('error', resForgot.message || FORGOT_PASSWORD_TOAST.ERROR)
        }
      } else {
        $alertMsg('error', FORGOT_PASSWORD_TOAST.ERROR)
      }
    }
    setTimeout(() => {
      isLoadingSubmit.value = false
    }, 1000)
  } catch (error) {
    isLoadingSubmit.value = false
  }
}

const hideModal = (): void => {
  closeAllModal()
  navigateTo({
    path: location.pathname,
    query: { ...route.query, popup: undefined, token: undefined }
  })
}
</script>

<style lang="scss" scoped src="assets/scss/components/common/form/forgot-password.scss" />
